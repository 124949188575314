<template>
    <div class="col-md-4">
        <div
          class="modal fade"
          id="3d_group_box"
          tabindex="-1"
          role="dialog"
          aria-labelledby="modal-form"
          aria-hidden="true"
      >
          <div
              class="modal-dialog modal- modal-dialog-centered modal-sm"
              role="document"
          >
              <div class="modal-content">
                  <div class="modal-body">
                    <!-- For Quick Entering Amount  -->
                    <div class="form-group mb-0 mt-3 px-3 py-3 mx-2" style="border: 1px solid #DEE2E6; border-radius: 5px;">
                        <span class="text-sm text-uppercase">Quick Input</span>
                        <div class="d-flex" style="border: 1px solid #DEE2E6; border-radius: 5px; width: 100%; height: 40px;" @click="showKeyBoard = !showKeyBoard">
                        <div class="pl-3 my-auto">
                            {{ numberAmount }}
                        </div>
                        </div>
                        <van-number-keyboard
                            v-model="numberAmount"
                            :show="showKeyBoard"
                            theme="custom"
                            :extra-key="['R', '/']"
                            close-button-text="Enter"
                            @hide="numberAmount.length > 0 ? quickSelectNumber() : ''"
                            @close="numberAmount.length > 0 ? quickSelectNumber() : ''"
                        />
                    </div>
                    <!-- End Quick Entering Amount  -->
                    <div class="d-flex flex-wrap px-2 py-3">
                        <a
                            href="#"
                            class="badge badge-pill badge-primary m-1"
                            style="text-align: center"
                            @click="quickNumbers('tri')"
                            >{{
                                $store.state.language === "en"
                                    ? "Tri"
                                    : "သုံးလုံးထွိုင်"
                            }}</a
                        >
                        <a
                            href="#"
                            class="badge badge-pill badge-primary m-1"
                            style="text-align: center"
                            @click="quickNumbers('century')"
                            >{{
                                $store.state.language === "en"
                                    ? "ရာပြည့်"
                                    : "ရာပြည့်"
                            }}</a
                        >
                    </div>

                  </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            showKeyBoard: false,
            numberAmount: '',
            numbers: [],
            allNumbers: [],
        };
    },
    async mounted() {
        await this.fetchNumbers();
    },
    methods: {
        quickNumbers(type){
            if(type === 'tri'){
                this.numbers = this.allNumbers.filter((el) => {
                    const threedArray = el.number
                    .toString(10)
                    .split('')
                    .map((t) => t);
                    const firstNum = threedArray[0];
                    const secondNum = threedArray[1];
                    const thirdNum = threedArray[2];
                    return firstNum === secondNum && secondNum === thirdNum;
                });
            this.$emit('quickGroupNumbers', this.numbers);
            }
            else if (type === 'century') {
                this.numbers = this.allNumbers.filter((el) => {
                    // const threedArray = el.number
                    // .toString(10)
                    // .split('')
                    // .map((t) => t);
                    // const firstNum = threedArray[0];
                    // const secondNum = threedArray[1];
                    // const thirdNum = threedArray[2];
                    return el.number == 100 || el.number == 200 || el.number == 300 || el.number == 400 || el.number == 500 || el.number == 600 || el.number == 700 || el.number == 800 || el.number == 900;
                });
            this.$emit('quickGroupNumbers', this.numbers);
            }
        },
        async fetchNumbers() {
            try {
                const res = await axios.post('/threed_numbers', {
                    limit: 'all',
                });
                this.numbers = res.data.data;
                this.allNumbers = res.data.data;
            } catch (error) {
                console.log(error);
            }
        },
        generatePermutations(str) {
            if (str.length <= 1) return [str];
            let permutations = [];
            for (let i = 0; i < str.length; i++) {
                const char = str[i];
                const remainingChars = str.slice(0, i) + str.slice(i + 1);
                const remainingPerms = this.generatePermutations(remainingChars);
                for (const perm of remainingPerms) {
                permutations.push(char + perm);
                }
            }
            return permutations;
        },

        quickSelectNumber() {
            let inputString = this.numberAmount;
            // Split the string into an array using "/"
            let elements = inputString.split("/");
            let result = [];
            
            elements.forEach(item => {
            if (item.includes("R")) {
                // Split the input at "R"
                let [partBeforeR, partAfterR] = item.split("R");

                if (partBeforeR.length === 3) {
                // If `partBeforeR` has exactly 3 digits, treat it as the number
                let number = partBeforeR;
                let soloAmount = partAfterR;

                // Generate unique permutations of `number`
                let uniquePermutations = [...new Set(this.generatePermutations(number))];

                // Add each permutation with the same `solo_amount` from `partAfterR`
                uniquePermutations.forEach(permutedNumber => {
                    result.push({ number: permutedNumber, solo_amount: soloAmount });
                });
                } else {
                // If `partBeforeR` has more than 3 digits, handle normally (first 3 digits are `number`)
                let number = partBeforeR.slice(0, 3);
                let remainingAmount = partBeforeR.slice(3);

                // First entry with `number` and `remainingAmount` as `solo_amount`
                result.push({ number, solo_amount: remainingAmount });

                // Generate unique permutations of `number` and add with `partAfterR` as `solo_amount`
                let uniquePermutations = [...new Set(this.generatePermutations(number))].filter(permutedNumber => permutedNumber !== number);
                uniquePermutations.forEach(permutedNumber => {
                    result.push({ number: permutedNumber, solo_amount: partAfterR });
                });
                }
            } else {
                // Handle case without "R"
                let number = item.slice(0, 3);
                let soloAmount = item.slice(3);
                result.push({ number, solo_amount: soloAmount });
            }
            });


            console.log(result);
            result = result.map(item => ({
                ...item,          // Spread the existing properties of the object
                ...(this.allNumbers.find( (el) => parseInt(el.number, 10) === parseInt(item.number, 10))), // Add or update the `solo_amount` property
                draw: this.drawTime
            }));

            this.$emit('quickNumbers', result);
        },
    },
}
</script>
<style scoped>
.badge.badge-pill {
  padding: 10px;
  border: 1px solid transparent;
  color: black !important;
}
.badge-primary[href]:hover{
  background-color: black;
  color: white !important;
}
</style>